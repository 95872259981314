@mixin cube-bg(){
  padding: 1rem 1.2rem;
  background-color: rgba(255,255,255,0.15);
  border-radius: 0.2rem;
}

.bpk-landing-container{
  width: 100%;
  .bpk-nav-bar{
    z-index: 1;
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    padding: 3rem 2rem;
    img {
      width: 350px;
    }
    > ul{ 
      display: flex;
      margin-left: auto;
      list-style: none;
      align-items: center;
      margin-bottom: 0px;
      >li {
        font-weight: 400;
        font-size: 1.1rem;
        margin: unset;
        font-weight: bolder;
        margin-left: 1.5rem;
        cursor: pointer;
        position: relative;
      }

      &:first-child {
        padding: 0px !important;
        color: black !important;
        margin-right: -10px !important;

        button {
          background-color: rgba(255,255,255,0.2) !important;
        }
      }

      >li:not(:first-child) {
        color: white;
        &::after {
          transition: width ease-in-out 250ms;
          content: '';
          position: absolute;
          height: 1px;
          width: 0px;
          background-color: white;
          bottom: 0;
          left: 0;
        }
        // &:hover {
        //   &::after {
        //    width: 8.5ch;
        //   }
        // }
      }
    }
  }
  .bpk-landing-header {
    background-color: #252525;
    padding: 3rem 2rem;
    background-image: url(https://whistleblower.proof-point.com/assets/img/magic-keyboard-1109543-SW2-scaled.jpg);
    height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center;
    
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: #212529;
      opacity: 0.5;
      height: inherit;
    }

    >*{
      z-index: 1;
    }

    >h1 {
      margin-top: 6rem;
      font-weight: 800;
      font-size: 4.2rem;
      display: block;
      color: white;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }
    >h6 {
      color: white;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.1;
      display: block;
      margin-top: 0.625rem;
    }
  }
  
  .bpk-landing-body{
    min-height: 90vh;
  }
  .bpk-landing-footer {
    grid-gap: 2rem 0rem;
    color: white;
    background-color: #252525;
    padding: 3rem 2rem;
    >[class*="col-"] {
      h5 {
        // color: #f18e03;
        display: block;
        font-size: clamp( 1rem, 2.5vh, 2.5rem );
        font-weight: bold;
        margin-bottom: 1.2rem;
      }
      >:is( h6, a ) {
        font-weight: 400;
        font-size: clamp( 0.8rem, 1.8vh, 2rem );
        margin-bottom: 0.8rem;
      }
      >button {
        position: relative;
        display: block;
        cursor: pointer;
        color: white;
        text-decoration: none;
        background-color: transparent;
        box-shadow: none;
        border: none;

        &::after {
          transition: width ease-in-out 250ms;
          content: '';
          position: absolute;
          height: 1px;
          width: 0px;
          background-color: white;
          bottom: 0;
          left: 0;
        }
        // &:hover {
        //   &::after {
        //    width: 200px;
        //   }
        // }
      }

    }

    .bpk-social-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-gap: 1rem 0rem;
      justify-content: space-between;
    }
  }

  .bpk-icon-button {
    text-align: center;
    cursor: pointer;
    height: max-content;
    transition: background-color ease-in 100ms;
    @include cube-bg();
    
    svg {
      width: 2rem;
      height: 2rem;
      margin-bottom: 0.7rem;
    }
    p {
      font-weight: 500;
      min-width: 60px;
      margin-bottom: 0px !important;
    }

    &:hover {
      background-color: rgba(255,255,255,0.2);
    }
  }
}
