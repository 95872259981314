.bkp-remark-mansory {
  display: grid;
  grid-template-columns: repeat( 3, minmax(20rem, 1fr) );

  @media ( max-width: 1200px ) {
    grid-template-columns: repeat( 2, minmax(20rem, 1fr) );
  }

  @media ( max-width: 980px ) {
    grid-template-columns: repeat( 1, minmax(20rem, 1fr) );
  }
  gap: 1rem;

  > * {
    margin-bottom: 1rem;
  }
}

.bkp-desc-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}