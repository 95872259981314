.bkp-case-card {
  margin-top: 1rem;
  @media ( min-width: 992px ) {
    max-width: 350px;
    margin-left: auto;
    margin-top: 0rem;
  }
}

.bkp-case-type_badge {
  padding: 0.3rem 0.6rem;
  border-radius: 0.6rem;
  color: #fff;
  background-color: #6c757d;
  margin-right: 7px;
  font-weight: 500;
}

.bkp-case__actions{
  margin-bottom: 0.4rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  @media ( min-width: 992px ) {
    justify-content: flex-end;
  }
  padding-bottom: 0.5rem;
  border-radius: 0.3rem;
  color: #fff;
  transition: transform 100ms ease-in-out;

  .bkp-case__actions-child {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.2rem;

    :is( span, p ){
      display: inline;
      font-weight: 500;
      margin-left: 10px;
      font-size: 0.9rem;
    }
  
    svg {
      width: 2.2em;
      height: 2.2em;
      border-radius: 0.3rem;
    }

    @media (hover) {
      span {
        display: none;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }
}